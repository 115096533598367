import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  // tslint:disable-next-line: component-selector
  selector: '[field-validation]',
  templateUrl: './field-validation.component.html',
  styleUrls: ['./field-validation.component.scss'],
})
export class FieldValidationComponent {
  @Input() control: FormControl | AbstractControl;
  @Input() submit = false;

  private defaultErrors = {
    required: 'Este campo é requerido!',
    email: 'Este campo deve conter um e-mail válido!',
    cpf: 'Informe um CPF válido!',
    invalidDate: 'Data inválida',
  };

  getErrorMessage(): string | null {
    if (this.control) {
      for (const validatorName in this.control.errors) {
        if (
          this.submit ||
          (this.control.errors.hasOwnProperty(validatorName) &&
            this.control.touched)
        ) {
          return this.defaultErrors[validatorName];
        }
      }
    }

    return null;
  }
}
