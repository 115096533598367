import { Injectable, OnDestroy } from '@angular/core'
import { BehaviorSubject, Subscription } from 'rxjs'
import { LoadingBarService } from '@ngx-loading-bar/core'

@Injectable({
  providedIn: 'root',
})
export class LoadingService implements OnDestroy {
  private subscriptions = new Subscription()
  private isRequesting = new BehaviorSubject<boolean>(false)

  constructor(private loadingService: LoadingBarService) {
    this.startListeningRequests()
  }

  private startListeningRequests() {
    this.subscriptions.add(
      this.loadingService.value$.subscribe((value) =>
        this.isRequesting.next(value && value < 100)
      )
    )
  }

  get isLoading() {
    return this.isRequesting.asObservable()
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }
}
