import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { CEP } from '../models/cep';
import { GetContract } from '../models/contract';
import { FileUpload } from '../models/file';
import { GetPaymentSlip } from '../models/payment-slip';
import { Proposal, ProposalPanel, ProposalSuccess } from '../models/proposal';
import { APIResponse } from '../models/response';
import { Simulation } from '../models/simulation';
import { ServerService } from './server.service';

@Injectable({
  providedIn: 'root',
})
export class ProposalService {
  constructor(private server: ServerService, private http: HttpClient) {}

  getCommercialTable(id): Observable<any[]> {
    return this.http
      .get<APIResponse>(this.server.url(`Proposta/tabela/${id}`))
      .pipe(pluck('value'));
  }

  getProposal(id): Observable<any> {
    return this.http
      .get<APIResponse>(this.server.url(`Proposta/${id}`))
      .pipe(pluck('value'));
  }

  getDocument(id): Observable<any> {
    return this.http
      .get<APIResponse>(this.server.url(`Proposta/documento/${id}`))
      .pipe(pluck('value'));
  }

  createProposal(proposal: object) {
    return this.http
      .post<APIResponse<ProposalSuccess>>(this.server.url(`Proposta`), proposal)
      .pipe(pluck('value'));
  }

  updateProposal(proposal: object, id: number) {
    return this.http
      .put<APIResponse>(this.server.url(`Proposta/${id}`), proposal)
      .pipe(pluck('value'));
  }

  sendDocument(file: FileUpload) {
    return this.http
      .post<APIResponse>(this.server.url(`Proposta/documento`), file)
      .pipe(pluck('value'));
  }

  sendBiometry(params: object) {
    return this.http
      .post<APIResponse>(this.server.url(`Proposta/biometria`), params)
      .pipe(pluck('value'));
  }

  simulateProposal(params): Observable<Simulation> {
    return this.http
      .post<APIResponse>(this.server.url(`Proposta/simulacao`), params)
      .pipe(pluck('value'));
  }

  getCEP(cep: string): Observable<CEP> {
    return this.http.get<CEP>(`https://viacep.com.br/ws/${cep}/json/`);
  }

  getProposalList(params?: { cpf: string }) {
    return this.http
      .get<APIResponse<ProposalPanel[]>>(this.server.url('Proposta/painel'), {
        params: params,
      })
      .pipe<ProposalPanel[]>(pluck('value'));
  }

  printPaymentSlip(proposalId: number) {
    return this.http.get<APIResponse<GetPaymentSlip>>(
      this.server.url(`proposta/boletos/${proposalId}`)
    );
  }

  generateContract(proposalId: number) {
    return this.http.get<APIResponse<GetContract>>(
      this.server.url(`proposta/contrato/${proposalId}`)
    );
  }

  cancelProposal(proposalId: number) {
    return this.http.delete<APIResponse<any>>(
      this.server.url(`proposta/cancelamento/${proposalId}`)
    );
  }


  // Integrador Financeiras
  getListaPropostasExternas(proposalId: number) {
    return this.http.get<APIResponse<any>>(this.server.url(`IntegradorFinanceiras/propostas-aprovadas/${proposalId}`)).toPromise()
  }

  postSelecionaPropostaExterna(internalProposalId: number, externalProposalId: number){
    return this.http.post<APIResponse<any>>(this.server.url(`IntegradorFinanceiras/seleciona-proposta-externa/${internalProposalId}/${externalProposalId}`), {}).toPromise();
  }
}
